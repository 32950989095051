



















































































































































































































































































































































































import {Component, Vue} from 'vue-property-decorator';
import myHeader from '@/components/Header.vue';
import myFooter from '@/components/Footer.vue';
import {getQinToken} from "@/api/courseWork/myHomework/answerSheet";
import {
  getIdCardInfo,
  getStuInfo,
  saveFromData,
  postExamInfo,
  getExamInfo,
  getKM
} from "@/api/degreeeEnglish/degreeeEnglish";

// Define an interface for the exam subject

@Component({
  components: {
    myHeader,
    myFooter
  }
})
export default class DegreeEnglish extends Vue {
  uploadFrom = {
    token: '',
    key: ''
  };
  examData = []
  examKmobj = {};
  isPlay = 0;
  isTicket = 0;
  formData = {
    studentName: '',
    idNumber: '',
    studyType: '函授',
    school: '',
    educationLevel: '专升本',
    major: '',
    idPhotoFront: '',
    idPhotoBack: '',
    avatar: '',
    session_id: '',
    stu_id: 0
  }
  dialogVisible = false;
  agreementChecked = false;
  confirmDialogVisible = false;
  showBlog = 1;
  submittedData = {};
  examForm = {
    examArea: '',
    examSubject: '学位英语',
    selectedSubjectLabel: 1
  };
  examAreas = [

    // 可以根据需要添加更多考区
  ];
  examKeMu = [];
  examRules = {
    examArea: [
      {required: true, message: '请选择考区', trigger: 'change'}
    ],
    examSubject: [
      {required: true, message: '请选择报考科目', trigger: 'change'}
    ]
  };
  failReasonDialogVisible = false;
  appId: any;
  examDetailsDialogVisible = false;
  selectedExam = null;
  private rules = {
    educationLevel: [{required: true, message: '请选择专业层次', trigger: 'change'}],
    major: [{required: true, message: '请输入专业名称', trigger: 'blur'}],
    idPhotoFront: [{required: true, message: '请上传身份证正面照片', trigger: 'change'}],
    idPhotoBack: [{required: true, message: '请上传身份证反面照片', trigger: 'change'}],
    avatar: [{required: true, message: '请上传个人头像', trigger: 'change'}]
  }

  viewDetails(exam: any) {
    this.selectedExam = exam;
    this.examDetailsDialogVisible = true;
  }

  payForExam(exam: never) {
    this.$message.error('在线缴费功能正在维护中，如需缴费请联系教务老师！');
  }

  handleIdPhotoFrontSuccess(response: any) {
    this.formData.idPhotoFront = 'https://c.zikaoj.com/' + response.key;
    //  传递识别信息
    getIdCardInfo({url: this.formData.idPhotoFront}).then((res: any) => {
      console.log(res);
      if (res.code == 200) {
        this.formData.studentName = res.data.name;
        this.formData.idNumber = res.data.id_card;
        this.formData.session_id = res.data.session_id;
      } else {
        this.$message.error(res.error);
        this.formData.idPhotoFront = '';
      }

    });
  }

  handleIdPhotoBackSuccess(response: any) {
    this.formData.idPhotoBack = 'https://c.zikaoj.com/' + response.key;
  }

  handleAvatarSuccess(response: any) {
    this.formData.avatar = 'https://c.zikaoj.com/' + response.key;
  }

  beforeUpload(file: File) {
    const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJPG) {
      this.$message.error('上传图片只能是 JPG 或 PNG 格式!');
    }
    if (!isLt2M) {
      this.$message.error('上传图片大小不能超过 2MB!');
    }
    return (new Promise((resolve, reject) => {
      // 获取七牛云token
      getQinToken({}).then((res: any) => {
        this.uploadFrom.token = res.token;
        this.uploadFrom.key = res.key;
        resolve();
      });
    }));
  }

  created() {
    const student = JSON.parse(localStorage.getItem('student') as string);
    this.formData.school = student.academy_name;
    this.formData.major = student.major_name;
    this.formData.stu_id = student.stu_id;
    this.getData();
    this.getkeMu();
  }

  submitForm() {
    (this.$refs.formRef as any).validate((valid: boolean) => {
      if (valid) {
        saveFromData({'formdata': this.formData}).then((res: any) => {
          if (res.code == 200) {
            this.$message.success('提交成功，等待审核');
            this.$router.go(0);
          } else {
            this.formData = {
              studentName: '',
              idNumber: '',
              studyType: '成考',
              school: '',
              educationLevel: '',
              major: '',
              idPhotoFront: '',
              idPhotoBack: '',
              avatar: '',
              session_id: '',
              stu_id: 0
            };
            this.$message.error(res.error);
          }
        });
      } else {
        return false;
      }
    });
  }

  openCommitmentDialog() {
    this.dialogVisible = true;
    this.agreementChecked = false;
  }

  handleNextStep() {
    if (!this.agreementChecked) {
      this.$message.warning('请先阅读并同意承诺书内容');
      return;
    }
    this.dialogVisible = false;
    this.confirmDialogVisible = true;
  }

  handleFinalConfirm() {
    (this.$refs.examFormRef as any).validate((valid: boolean) => {
      if (valid) {
        // 这里添加最终提交的逻辑
        const submitData = {
          ...this.formData,
          examArea: this.examForm.examArea,
          examSubject: this.examForm.examSubject,
          selectedSubjectLabel: this.examForm.selectedSubjectLabel,
          app_id: this.appId
        };
        console.log(submitData);
        // TODO: 调用API提交报考信息
        postExamInfo({'formdata': submitData}).then((res: any) => {
          if (res.code == 200) {
            this.$message.success('报考成功！');
            this.getExam(this.appId);
            this.confirmDialogVisible = false;
          } else {
            this.$message.error(res.error);
            this.confirmDialogVisible = false;
            this.getExam(this.appId);
          }
        });

        // 可以在这里添加后续理逻辑，比如跳转到其他页面
      } else {
        return false;
      }
    });
  }

  getData() {
    getStuInfo({'stu_id': this.formData.stu_id}).then((res: any) => {
      this.submittedData = res.data;
      this.appId = res.data.id || 0;
      this.getExam(res.data.id);
    });
  }

  getExam(id: number) {
    getExamInfo({'application_id': id}).then((res: any) => {
      console.log(res);
      this.examData = res.subjectList;
      this.isPlay = res.is_pay;
      this.isTicket = res.is_ticket;
    });
  }

  getkeMu() {
    getKM({}).then((res: any) => {
      console.log(res);
      this.examKeMu = res.dataHash;
      this.examAreas = res.areaHash;
    });
  }

  openFailReasonDialog() {
    this.failReasonDialogVisible = true;
  }

  resetSubmittedData() {
    this.submittedData = {};
    this.showBlog = 0;
    this.$message.success('请重新提交信息审核');

  }

  updateSelectedSubject(value: string) {
    console.log(value);
  }

  goToDegreePrint() {
    this.$router.push({path: '/degreePrint'});
  }

  showBlogClick() {
    this.showBlog = 0;
  }

}
